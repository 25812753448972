// Variables
// --------------------------

 
// Colors and backgrounds
// --------------------------

$darker			: #000000	!default;  
$lighter		: #FFFFFF	!default; 
$body			: #f5f5f5	!default; 
$red			: #c11824	!default; 
$menu-over		: #860000	!default; 
$menu-border	: #590000	!default; 
$title			: #222223	!default; 
$footer			: #373737	!default; 
$footer-p		: #c7c7c7	!default; 
$footer-bottom	: #242526	!default; 
$footer-menu	: #737373	!default; 
$email			: #e96866	!default;
$border-color	: #d31a18   !default;   
$select			: #f2f2f2	!default;
$select-border	: #d9d9d9	!default;
$select-text	: #616161	!default;
$select-arrow	: #919191 	!default;
$topcol			: #ac2023	!default;
$bottomcol		: #990d0f	!default;
$ft-border-color: #5f5f5f	!default;
$form-border	: #dcdcdc    !default;
$content-border	: #c9c9c9    !default;
$arrow-col  	: #acb0af    !default;





//== Typography
// -------------------------- 
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,800,300);
@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);

$main-font: 'Open Sans', sans-serif;
$sub-font: 'Lato', sans-serif;

$font-size-base:       	14px !default; 

$font-size-h1	: ceil(($font-size-base * 3.4))   !default; // ~48px
$font-size-h2	: ceil(($font-size-base * 2.57))  !default; // ~36px
$font-size-h3	: ceil(($font-size-base * 2.14))  !default; // ~30px
$font-size-h4	: ceil(($font-size-base * 1.71))  !default; // ~24px
$font-size-h5	: ceil(($font-size-base * 1.25))  !default; // ~20px
$font-size-h6	: ceil(($font-size-base * 1.29))  !default; // ~18px

$font-size-icon	: ceil(($font-size-base * 2.7))   !default; // ~39px
$font-size-1	: ceil(($font-size-base * 0.92))  !default; // ~13px
$font-size-2	: ceil(($font-size-base * 1.21))  !default; // ~17px
$font-size-3	: ceil(($font-size-base * 1.14))  !default; // ~16px
$font-footer    : ceil(($font-size-base * 1.78))  !default; // ~25px
$font-paragraph : ceil(($font-size-base * 0.78))  !default; // ~11px
$font-span      : ceil(($font-size-base * 0.85))  !default; // ~12px


