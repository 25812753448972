// import  variable
@import  "_mixins";


@media only screen and (min-width: 1025px) and (max-width: 1199px) {
	.main-menu ul li a{padding: 20px 16px;}
	.main-menu ul li:hover a { padding: 20px 16px 17px;}
	.language-switcher ul li a{ padding: 20px 0 20px 5px;}	
   
	.car-search-outer { margin-left: 12%; width: 92%;}
	.sub-page {margin-left: 3%;}
	.social-icons-left {width: 43px; padding: 9px;}
	.social-icons-left ul li { height: 24px;}
	.price-outer {width: 48%;}
	.car-search .dropdown.button, button.dropdown {padding-left:5px;}
	.btn-outer .find-btn {background-position:18% 18px;}
	.car-search .price-outer .f-dropdown { max-width: 34%;}
	.car-search .f-dropdown {max-width: 70.5%;}
	.car-search button, .car-search .button {font-size:$font-size-1;}
	.block-middle .price-outer {width: 100%;}
	.block-right .btn-outer .find-btn {background-position: 22% 33px;}
	.car-search.search-sub .f-dropdown {max-width: 24.3%;}
	
  	.detail-bottom-left {  width: 57.7%;}
	.thumb {  max-width: 136px;}
	.flex-direction-nav a:before {font-size:$font-footer;}
	.flex-direction-nav .flex-next { right: -14px;}
	.flex-direction-nav .flex-prev { left: -13px;}
	.footer-bottom-right span{font-size: 12px;}
	.product-thumb img { height: 100%; width: 100%;}
	.product-thumb .thumb-inner img { height: inherit; width: inherit;}
	.footer-menu{display:block !important;}
	
	
		
}  

@media only screen and (min-width: 767px) and (max-width: 991px) {
	.flexslider { max-width: 290px !important;}
	.product-thumb.active img { height: 100%; width: 100%;}
	.product-thumb .thumb-inner img { height: inherit; width: inherit;}
	.list-row ul li .single-product .product-details{ padding: 19px 10px 22px;}
	.list-row ul li .single-product .product-thumb{ height: 110px;}
	.btn-outer .find-btn {background-position:16% 13px; padding: 12px 5px 12px 24%; background-size:14%; font-size: 19px; }
	.slogan ul { width:77%; }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
	.tab-bar{display:block;}
	#header .menu-area{display:none;}
    .main-menu ul li{ width:100%; }
    .main-menu ul li a{padding: 12px 21px; width:100%;}
    .main-menu ul li:hover a{padding: 12px 21px 9px 21px;}
    .menu-area{ height:100%;} 
    .language-switcher{padding-left: 22px;}
	.more{margin-bottom:37px;}
	.product-category{margin-bottom:0;}
	.product-list .content-title h2{text-align: center;}	
	
	.car-search-outer { margin-left: 12%; width: 92%;}
	.social-icons-left {width: 43px; padding: 9px;}
	.social-icons-left ul li { height: 24px;}
	.price-outer {width: 100%;}
	.last-block{margin-left:0;}
	.car-search .dropdown.button, button.dropdown {padding-left:5px;}
	.car-search .f-dropdown {max-width: 70.5%;}
	.car-search button, .car-search .button {font-size:$font-size-1;}
	.car-search .f-dropdown li a {font-size:$font-size-1;}
	.text-right h1 {line-height: 43px;padding-bottom: 9px;font-size:$font-size-h2;}
	.text-right h2 {font-size:$font-size-h3;}
	.btn-outer .find-btn {background-position:14% 13px; padding: 16px 5px 12px 24%; background-size:14%; height:51px;}
	.btn-outer{height:51px;}
	.sub-page {margin-left: 5%;}
	.car-search.search-sub .f-dropdown {max-width: 24.3%;}
	.block-right .btn-outer .find-btn {background-position: 13% 33px;font-size:$font-size-h6;}
	.block-left {width: 31.5%;}
	.block-middle { width: 24.8%;}
	.block-right {width: 37%;}	
	
	.detail-bottom-left {  width: 57.7%;}
	.thumbnail-outer { max-width: 290px;}
	.single-feature{font-size:$font-size-1;}
	.detail-con {  padding: 27px 4px;}
	
	.footer-bottom-right span {text-align:center;width: 100%;}
	.contact-area-left p{font-size:$font-size-1;}
	.footer-left h3{font-size:$font-footer;}
	.footer-left h3 span{font-size:$font-footer;}
	.footer-right h3{font-size:$font-footer;}
	.contact-area-right a{font-size:$font-span;}
	.contact-area-right p {font-size:$font-paragraph;}
	.contact-area-left p {font-size: $font-paragraph;}
	
	.flexslider {  max-width: 429px;} 
	.flex-direction-nav .flex-next , .flex-direction-nav .flex-prev {right: -22px;}
	
	.product-thumb.active img { height: 100%; width: 100%;}
	.product-thumb .thumb-inner img { height: inherit; width: inherit;}
	
	.footer-menu{display:none !important;}
	.left-off-canvas-menu{display:block;}
	.product-list .list-row ul { margin-bottom:0rem !important; }
	.slogan ul { width:77%; }
	.banner-container{	background:url("../images/content/banner.jpg") no-repeat left 0; }
}
@media only screen and (min-width: 641px) and (max-width: 767px) {
	.product-list { float: none; margin: 0 auto; max-width: 502px; }
	.list-row ul li{ width: 47%;  margin-left: 4%;}
	.list-row ul li.odd{ margin-left: 0;}	
	.product-category h4{text-align: center;}
	.product-list .viewall{display:none;}
	.slogan ul { width:77%; }
}

@media only screen and (max-width: 640px) {
	.tab-bar{display:block;}
	#header .menu-area{display:none;}
	.main-menu ul li{ width:100%; }
	.main-menu ul li a{padding: 12px 21px; width:100%;}
	.main-menu ul li:hover a{padding: 12px 21px 9px 21px;}
	.menu-area{ height:100%;} 
	.language-switcher{padding-left: 22px;}
	.product-area {padding-top: 20px;}
	.more{margin-bottom:37px;}
	.product-category{margin-bottom:0;}
	.product-list .content-title h2{text-align: center;}
	.slogan ul { width:77%; }

	.price-outer {width:100%;}
	.btn-outer {float:none;margin:0 auto;display:table;height:55px;}
	.btn-outer .find-btn {height:55px;padding: 15px 5px 15px 22%;background-position:73px 14px;}
	.text-right {padding-top: 30px;}
	.text-right h1 {line-height: 36px;padding-bottom:0;font-size:$font-size-h3;}
	.text-right h2 {font-size:$font-size-h4;}
	.last-block {margin-left:0;}
	.car-search .f-dropdown {margin-left: 9%;max-width: 79% !important;width: 100%;}
	.block-left,.block-middle {width:100%;margin-right:0;}
	.block-right {width:100%;margin-top:20px;}
	.block-right .btn-outer {height: 60px;}
	.block-right .btn-outer .find-btn { background-position: 73px 17px;height: 60px;padding: 13px 5px 23px 18%;}
	.search-sub {margin-bottom:0!important;}
	.car-search {max-width:500px;float:none;display:table;margin:0 auto 20px;width:100%; padding:15px 4% 20px;}
	.car-search button, .car-search .button {padding:11px 21px;}
	.social-icons-left {top:105px;}
	.text-right h2 {line-height: 28px;}
	.car-search h3 {line-height: 30px;font-size:$font-footer;padding-bottom: 8px;}
	.banner-container { padding: 37px 0 35px;}
	.banner-container{	background:url("../images/content/banner.jpg") no-repeat left 0; }
	
	.detail-left , .detail-right{width:100%;}
	.detail-right ul li{padding:0 0px 0 15px;}	
	.detail-right ul { float: left;}
	.detail-bottom-left  , .form-left , .form-right , .address-left , .address-right{ width: 100%;}
	.detail-bottom-right { margin-left: 0px; width: 100%;}
	.address-right p { padding-left: 0px;}
	.form-outer-con {  padding: 10px 0 20px;}
	.detail-con { padding: 20px 0;}
	.detail-con-middle { padding: 30px 10px 21px 20px;}
	
	.footer-left {text-align: center; padding-bottom: 15px;}
	.social-icons ul {text-align: center;}
	.social-icons ul li {display: inline-block; float:none;}
	.footer-bottom-left{display:none;}
	.footer-bottom-right span { text-align: center; width: 100%;}
	.footer-right {text-align: center;}
	.contact-area-left {text-align: center;width: 100%;padding-bottom: 15px;}
	.contact-area-right {text-align: center;width: 100%;}
	.contact-area-right span {width: 100%;  }
	.contact-area-right a { width: 100%;}
	.footer-container-top {padding: 20px 0;margin-top:5px;}
	.footer-left h3 {font-size: $font-footer;}
	.footer-left h3 span {font-size: $font-footer;}
	.social-icons {margin-top: 20px;}
	.footer-left p {margin-top: 20px;}
	.footer-right h3{font-size: $font-footer;}	
	
	
	.flexslider { max-width: 290px;}
	.address-outer{margin-bottom: 0px;}
	
	.left-off-canvas-menu{display:block;}

	.footer-menu{display:none !important;}
	.more {display:none !important;}
	.list-row ul li{ width: 47%;  margin-left: 4%;}
	.list-row ul li.odd{ margin-left: 0;}
	.product-list .list-row ul { margin-bottom:0rem !important; }
}

@media only screen and (min-width:480px) and (max-width: 640px) {
	.list-row ul li{ width: 47%;  margin-left: 4%;}
	.list-row ul li.odd{ margin-left: 0;}
	.list-row ul li .single-product .product-thumb{ height: 110px;}
	.product-list .list-row ul { margin-bottom:0rem !important; }
	.banner-container{	background:url("../images/content/banner.jpg") no-repeat left 0; }
	
}

@media only screen and (max-width: 479px) {
	.product-list .content-title{ text-align:center;}
	.product-list .content-title h2{font-size: 31px; width:100%; text-align:center;  padding-bottom: 28px;}
	.product-list .viewall { float: none; margin: 0 auto; max-width: 155px; padding:10px 10px; display: inline-block; margin-bottom: 10px;}

	.car-search .f-dropdown {max-width: 80% !important;}
	.social-icons-left {width: 45px;}
	.social-icons-left ul li {height: 25px;}
	.btn-outer .find-btn {background-position: 46px 14px;}
	.block-right .btn-outer .find-btn { background-position: 49px 21px;background-size: 10% auto;}
	
	.single-half {width:100%;}
	.form-outer input { height: 27px;   padding: 2px 6px;}
	.form-outer label{font-size:$font-size-base;}
	.form-outer {   padding: 20px 10px 10px;}
	.btn-div {  margin: 10px 0 10px;}	
	.flexslider { max-width: 146px;}
	
	.product-category h4{text-align: center;}
	.list-row ul { float: none; margin: 0 auto; max-width: 251px; }
	.list-row ul li{ width: 100%;  margin-left: 0;}
	
	.product-list .list-row ul { margin-bottom:0rem !important; }
	.slogan ul { width:81%; }
	.banner-container{	background:url("../images/content/banner.jpg") no-repeat left 0; }
}



